<template>
	<md-content>
		<div id="app" class="main">
			<!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
			<router-view />

			<md-snackbar
				:md-position="center"
				:md-duration="duration"
				:md-active="$store.state.showMsg ? true : false"
				md-persistent
			>
				<span>{{ $store.state.showMsg }}</span>
				<md-button class="md-primary" @click="closeMsg()"> Close </md-button>
			</md-snackbar>
		</div>
	</md-content>
</template>

<script>
export default {
	name: "Main",
	data() {
		return {
			center: "center",
			// "theme":true,
			duration: 4000,
			// theme: null,
		};
	},
	computed: {
		showMsg() {
			return this.$store.state.showMsg;
		},
	},

	beforeDestroy() {
		this.unsubcribe();
	},

	async mounted() {
		this.theme = await this.$store.dispatch("setTheme");

		this.$store.commit("loadUser");
		if (this.$store.state.currentUser) {
			this.$store.dispatch("getPayment");
			this.$store.dispatch("getInvestment");

			this.$store.dispatch("getWithdraw");

			this.$store.dispatch("getConfig");
			this.$store.dispatch("getNotification");
		}
		// console.clear();
	},
	methods: {
		closeMsg() {
			this.$store.commit("showMsg", "");
		},
	},
};
</script>

<style scoped>
.main {
	padding: 0px;
	margin: 0px;
	height: 100vh !important;
}
</style>
