var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.users.length === 0)?_c('md-empty-state',{attrs:{"md-icon":"persons","md-label":"No User Found","md-description":"You currenly have no user record"}}):_vm._e(),(_vm.users.length !== 0)?_c('md-table',{staticStyle:{"overflow":"auto"},attrs:{"md-sort":"timestamp","md-sort-order":"asc","md-card":""},scopedSlots:_vm._u([{key:"md-table-row",fn:function({ item }){return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":"Name","md-sort-by":"firstName"}},[_vm._v(" "+_vm._s(item.firstName)+"_"+_vm._s(item.lastName))]),_c('md-table-cell',{attrs:{"md-label":"Email","md-sort-by":"email"}},[_vm._v(_vm._s(item.email)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Telephone","md-sort-by":"tel"}},[_vm._v(_vm._s(item.tel)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Verification","md-sort-by":"verified"}},[_vm._v(_vm._s(item.verified))]),_c('md-table-cell',{attrs:{"md-label":"Status","md-sort-by":"isEnabled"}},[_c('md-button',{staticClass:"md-dense md-raised md-primary",style:([item.isEnabled ? _vm.GreenStyle : _vm.RedStyle]),on:{"click":function($event){return _vm.updateUserState(item.uid, {
              email: item.email,
              isEnabled: !item.isEnabled,
            })}}},[_vm._v(_vm._s(item.isEnabled ? "Active" : "Disabled"))])],1),_c('md-table-cell',{attrs:{"md-label":"Admin Status","md-sort-by":"isAdmin"}},[_c('md-button',{directives:[{name:"st",rawName:"v-st"}],staticClass:"md-dense md-raised md-primary",style:([item.isAdmin ? _vm.RedStyle : _vm.InfoStyle]),on:{"click":function($event){return _vm.updateUserState(item.uid, {
              email: item.email,
              isAdmin: !item.isAdmin,
            })}}},[_vm._v(_vm._s(item.isAdmin ? "Remove Admin" : "Make Admin"))])],1),_c('md-table-cell',{attrs:{"md-label":"Admin Status","md-sort-by":"isAdmin"}},[_c('md-button',{directives:[{name:"st",rawName:"v-st"}],staticClass:"md-dense md-raised md-primary",style:([_vm.RedStyle]),on:{"click":function($event){return _vm.removerUser(item.uid, {
              email: item.email,
            })}}},[_vm._v("Delete")])],1),_c('md-table-cell',{attrs:{"md-label":"Last_login","md-sort-by":"last_login","md-numeric":""}},[_vm._v(_vm._s(item.last_login))]),(item.progress)?_c('md-table-cell',{attrs:{"md-label":""}},[_c('md-progress-spinner',{attrs:{"md-diameter":20,"md-stroke":3,"md-mode":"indeterminate"}})],1):_vm._e()],1)}}],null,false,1301507386),model:{value:(_vm.searchResult),callback:function ($$v) {_vm.searchResult=$$v},expression:"searchResult"}},[_c('md-table-toolbar',[_c('div',{staticClass:"md-toolbar-section-start"},[_c('h1',{staticClass:"md-title"},[_vm._v("Registerd Users")])]),_c('md-field',{staticClass:"md-toolbar-section-end",attrs:{"md-clearable":""}},[_c('md-input',{attrs:{"placeholder":"Search by email..."},on:{"input":_vm.findByEmail},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('md-table-empty-state',{attrs:{"md-label":"No users found","md-description":`No user found for this '${_vm.search}' query. Try a different email or create a new user.`}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }