/* eslint-disable */


export default class UTILS {


  /**
   * Removes Special Characters from a string
   * @param arg string
   */
  static sanitizer(arg) {

    const data = arg;

    // clean data
    for (const key in arg) {
      const element = data[key];
      // return non string value
      if (typeof element === "string" && element !== undefined && element !== null) {
        data[key] = element.replace(/[^\w@\-_\s\.]/g, '');
      }
    }

    // return clean data
    return data;
  }



  /**
   * Add 'n' days to a specified date and returns the new data in milliseconds
   * @param date DateTime
   * @param days number
   */
  static addDaysToDate(date, days) {
    if (!date) date = new Date();
    return new Date(date.getTime() + days * 24 * 60 * 60 * 1000).valueOf();
  }

  static moneyFormat(num, symbol) {
    const p = num.toFixed(2).split(".");
    const e = p[0].split("").reverse().reduce(function (acc, num, i, orig) {
      return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
    })
    return `${!symbol ? "$" : symbol} ${e}.${p[1]}`;
  }



  /**
   * Removes whitespace from string
   * @param text string
   */
  static removeWhitespace(text) {
    return text
      .trim()
      .split("")
      .filter(e => {
        return e !== " ";
      })
      .join("");
  }


  /**
   * Add 'n' minutes to a specified date and returns the new data in milliseconds
   * @param minutes number
   * @param date DateTime
   */
  static addMinutesToDate(minutes, date) {
    if (!date) date = new Date();
    return new Date(date.getTime() + minutes * 60 * 1000).valueOf();
  }






  /**
   * Randoms list of array
   * @param array Array<any>
   */
  static shuffleArray(array) {
    return array.sort(() => Math.random() - 0.5);
  }






  /**
   * Generate random 16 char string with optional prefix for customization
   * @param prefix string(optional)
   */
  static generateRef(prefix = "") {
    const strArr = UTILS.shuffleArray(
      `ABCDEFGHIJKLMNOPQRSTUVWXYZ${UTILS.generateNumber()}`.split("")
    )
      .join("")
      .substring(0, 16)
      .split("");
    return `${prefix}${UTILS.shuffleArray(strArr).join("")}`;
  }





  /**
   * Generate random numbers
   */
  static generateNumber() {
    const random = Date.now().toString();
    const sub = random.substr(6, random.length);

    const salt = Math.floor(100 + Math.random() * 900);
    const num = Number(`${salt}${sub}`);
    return `${num}`;
  }









}
