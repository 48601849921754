<template>
  <div>
    <md-empty-state
      v-if="users.length === 0"
      md-icon="persons"
      md-label="No User Found"
      md-description="You currenly have no user record"
    >
      <!-- <md-button class="md-primary md-raised"></md-button> -->
    </md-empty-state>

    <md-table
      v-if="users.length !== 0"
      v-model="searchResult"
      md-sort="timestamp"
      md-sort-order="asc"
      style="overflow:auto"
      md-card
    >
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Registerd Users</h1>
        </div>

        <md-field md-clearable class="md-toolbar-section-end">
          <md-input
            placeholder="Search by email..."
            v-model="search"
            @input="findByEmail"
          />
        </md-field>
      </md-table-toolbar>

      <md-table-empty-state
        md-label="No users found"
        :md-description="
          `No user found for this '${search}' query. Try a different email or create a new user.`
        "
      >
      </md-table-empty-state>
      <!-- <md-table-row>
        <md-table-head md-numeric>Symbol</md-table-head>
        <md-table-head>Currency</md-table-head>
        <md-table-head>Buy</md-table-head>
        <md-table-head>Sell</md-table-head>
        <md-table-head>Last</md-table-head>
      </md-table-row> -->

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Name" md-sort-by="firstName">
          {{ item.firstName }}_{{ item.lastName }}</md-table-cell
        >
        <md-table-cell md-label="Email" md-sort-by="email"
          >{{ item.email }}
        </md-table-cell>
        <md-table-cell md-label="Telephone" md-sort-by="tel"
          >{{ item.tel }}
        </md-table-cell>

        <md-table-cell md-label="Verification" md-sort-by="verified">{{
          item.verified
        }}</md-table-cell>

        <md-table-cell md-label="Status" md-sort-by="isEnabled">
          <md-button
            @click="
              updateUserState(item.uid, {
                email: item.email,
                isEnabled: !item.isEnabled,
              })
            "
            v-bind:style="[item.isEnabled ? GreenStyle : RedStyle]"
            class="md-dense md-raised  md-primary"
            >{{ item.isEnabled ? "Active" : "Disabled" }}</md-button
          >
        </md-table-cell>

        <md-table-cell md-label="Admin Status" md-sort-by="isAdmin">
          <md-button
            @click="
              updateUserState(item.uid, {
                email: item.email,
                isAdmin: !item.isAdmin,
              })
            "
            v-bind:style="[item.isAdmin ? RedStyle : InfoStyle]"
            class="md-dense md-raised  md-primary"
            v-st
            >{{ item.isAdmin ? "Remove Admin" : "Make Admin" }}</md-button
          >
        </md-table-cell>

        <md-table-cell md-label="Admin Status" md-sort-by="isAdmin">
          <md-button
            @click="
              removerUser(item.uid, {
                email: item.email,
              })
            "
            v-bind:style="[RedStyle]"
            class="md-dense md-raised  md-primary"
            v-st
            >Delete</md-button
          >
        </md-table-cell>
        <md-table-cell
          md-label="Last_login"
          md-sort-by="last_login"
          md-numeric
          >{{ item.last_login }}</md-table-cell
        >
        <md-table-cell v-if="item.progress" md-label="">
          <md-progress-spinner
            :md-diameter="20"
            :md-stroke="3"
            md-mode="indeterminate"
          />
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<style scoped>
.md-button {
  color: #fff !important;
}
</style>

<script>
import Http from "../../helpers/http";
import { HOST } from "../../store/global";

const searchByEmail = (list, arg) => {
  if (arg) {
    return list.filter((e) =>
      e.email.toLowerCase().includes(arg.toLowerCase())
    );
  }
  return list;
};

export default {
  name: "users",
  data() {
    return {
      search: "",
      trader: "",
      searchResult: [],
      users: [],
      GreenStyle: {
        background: "teal",
      },
      RedStyle: {
        background: "red",
      },
      InfoStyle: {
        background: "#448aff",
      },
    };
  },
  methods: {
    /** SEARCH TABLE */
    findByEmail() {
      this.searchResult = searchByEmail(this.users, this.search);
    },

    /** GET USERS */
    async getUsers() {
      const url = HOST + "/user/list";
      const req = await Http({ method: "GET", url });
      if (req.status) {
        this.users = req.data.map((e) => {
          return { ...e, progress: false };
        });
        this.searchResult = this.users;
      }
    },

    updateProgress(id, state) {
      this.searchResult.find((e) => e.uid === id).progress = state;
    },

    /** ON User Status Change */
    async updateUserState(id, arg) {
      const url = HOST + "/user/update";

      this.updateProgress(id, true);

      const req = await Http({ method: "POST", url, body: arg });
      this.updateProgress(id, false);

      if (!req.status) {
        return alert("Update Failed");
      }

      alert("Update Applied Successfully");

      location.reload();
    },

    onChangeTrader(uid, email) {
      const { trader } = this.searchResult.find((item) => item.uid === uid);

      if (!trader) return;
      this.updateUserState(uid, {
        email: email,
        trader: trader,
      });
    },

    /** ON Remove User*/
    async removerUser(id, arg) {
      const url = HOST + "/user/remove";

      this.updateProgress(id, true);

      const req = await Http({ method: "POST", url, body: arg });
      this.updateProgress(id, false);

      if (!req.status) {
        return alert("Unable to remove user at this time");
      }

      alert("User deleted successfully Successfully");
      this.getUsers();
      location.reload();
    },
  },

  mounted() {
    this.getUsers();
  },
};
</script>
