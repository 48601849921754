<template>
  <!-- Wrapper Starts -->
  <div class="wrapper">
    <div class="container-fluid user-auth">
      <div class="hidden-xs col-sm-2 col-md-2 col-lg-2"></div>
      <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
        <div class="form-container">
          <div>
            <!-- Logo Starts -->
            <center>
              <router-link to="/">
                <img
                  id="logo"
                  height="100"
                  width="200"
                  class="img-responsive mobile-logo"
                  src="images/logo.svg"
                  style="margin-bottom:10%"
                  alt="logo"
                />
              </router-link>
            </center>
            <!-- Logo Ends -->

            <!-- Password Reset Section Title Starts -->
            <div v-if="!otpSent">
              <div class="row text-center">
                <h2 class="title-head hidden-xs">
                  Password <span>Reset</span>
                </h2>
                <p class="info-form">
                  An OTP code will be sent too your email address
                </p>
              </div>

              <!-- Enter Email Form Starts -->
              <form style="text-align: start" @submit.prevent="sendOtp">
                <!-- Input Field Starts -->
                <div class="form-group">
                  <input
                    id="email"
                    v-model="$v.email.$model"
                    class="form-control"
                    name="email"
                    placeholder="EMAIL"
                    type="email"
                  />
                  <p
                    v-if="
                      (!$v.email.required || !$v.email.email) && $v.email.$dirty
                    "
                    class="text-danger"
                  >
                    valid email is required
                  </p>
                </div>
                <!-- Input Field Ends -->

                <!-- Submit Form Button Starts -->
                <div class="form-group">
                  <button
                    v-if="!isSendingOtpLoading"
                    :disabled="!formValid"
                    class="btn btn-primary"
                    type="submit"
                  >
                    Send Otp
                  </button>
                  <div class="text-center">
                    <md-progress-spinner
                      v-if="isSendingOtpLoading"
                      :md-diameter="20"
                      :md-stroke="3"
                      md-mode="indeterminate"
                    />
                  </div>
                  <p class="text-center">
                    Already have an account
                    <router-link to="login"> login </router-link>
                  </p>
                </div>

                <!-- Submit Form Button Ends -->
              </form>
              <!-- Login Form Ends -->
            </div>
            <!-- Password Reset Section Ends Here -->

            <!-- Verify Otp Auth Section Title Starts -->
            <div v-if="otpSent && !otpVerified">
              <div class="row text-center">
                <h2 class="title-head hidden-xs">Password <span>Reset</span></h2>
                <p class="info-form">Enter one time pin sent to <span class="text-primary font-weight-bold">{{ email }}</span></p>
              </div>

              <!-- Login Form Starts -->
              <form style="text-align: start" @submit.prevent="verifyOtp">
                <!-- Input Field Starts -->
                <div class="form-group">
                  <input
                    v-model="$v.otpCode.$model"
                    class="form-control"
                    name="otp"
                    placeholder="OTP CODE"
                    type="number"
                  />
                  <p
                    v-if="!$v.otpCode.required && $v.otpCode.$dirty"
                    class="text-danger"
                  >
                    valid otp code is required
                  </p>
                </div>
                <!-- Input Field Ends -->

                <center style="margin:20px; color:">
                  <md-button
                    class="md-dense md-primary"
                    @click="sendOtp"
                    style="width:10px"
                  >
                    Resend otp</md-button
                  >
                </center>

                <!-- Input Field Ends -->
                <!-- Submit Form Button Starts -->
                <div class="form-group">
                  <button
                    v-if="!isValidatingOtpLoading"
                    :disabled="!otpFormValid"
                    class="btn btn-primary"
                    type="submit"
                  >
                    Verify Otp
                  </button>
                  <div class="text-center">
                    <md-progress-spinner
                      v-if="isValidatingOtpLoading"
                      :md-diameter="20"
                      :md-stroke="3"
                      md-mode="indeterminate"
                    />
                  </div>
                  <p class="text-center">
                    I already have an account
                    <router-link to="login"> login now </router-link>
                  </p>
                </div>

                <!-- Submit Form Button Ends -->
              </form>
              <!-- Login Form Ends -->
            </div>
            <!-- Verify Otp Section Ends Here -->

            <!-- New Password Section Title Starts -->
            <div v-if="otpVerified">
                <div class="row text-center">
                <h2 class="title-head hidden-xs">Password <span>Reset</span></h2>
                <p class="info-form">Enter new password<span class="text-primary font-weight-bold"> {{ email }}</span></p>
              </div>
              <!-- Enter Email Form Starts -->
              <form style="text-align: start" @submit.prevent="updatePassword">
                <!-- Input Field Starts -->
                <div class="form-group">
                  <input
                    id="pwd"
                    v-model="$v.pwd.$model"
                    class="form-control"
                    name="pwd"
                    placeholder="Password"
                    type="password"
                  />
                  <p
                    v-if="!$v.pwd.required && $v.email.$dirty"
                    class="text-danger"
                  >
                    valid password is required
                  </p>
                </div>
                <!-- Input Field Ends -->

                <!-- Input Field Starts -->
                <div class="form-group">
                  <input
                    id="confirmPwd"
                    v-model="confirmPwd"
                    class="form-control"
                    name="confirmPwd"
                    placeholder="Confirm Password"
                    type="password"
                  />
                  <p v-if="confirmPwd !== pwd" class="text-danger">
                    valid confirm password is required
                  </p>
                </div>
                <!-- Input Field Ends -->

                <!-- Submit Form Button Starts -->
                <div class="form-group">
                  <button
                    v-if="!isPasswordLoading"
                    :disabled="(confirmPwd !== pwd)||(!pwd || !confirmPwd)"
                    class="btn btn-primary"
                    type="submit"
                  >
                    Reset Password
                  </button>
                  <div class="text-center">
                    <md-progress-spinner
                      v-if="isPasswordLoading"
                      :md-diameter="20"
                      :md-stroke="3"
                      md-mode="indeterminate"
                    />
                  </div>
                  <p class="text-center">
                    Already have an account
                    <router-link to="login"> login </router-link>
                  </p>
                </div>

                <!-- Submit Form Button Ends -->
              </form>
              <!-- Login Form Ends -->
            </div>
            <!-- Password Reset Section Ends Here -->
          </div>
        </div>
        <!-- Copyright Text Starts -->
        <p class="text-center copyright-text">
          <router-link to="/">TopstepTrade</router-link> © {{ year }} All Rights
          Reserved
          <router-link class="primary" to="/terms">Terms of Use</router-link>
          and <router-link to="/privacy">Privacy Policy</router-link>
        </p>
        <!-- Copyright Text Ends -->
      </div>
      <div class="hidden-xs col-sm-2 col-md-2 col-lg-2"></div>
    </div>
  </div>
  <!-- Wrapper Ends -->
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import Http from "../../helpers/http";
import { HOST } from "../../store/global";

export default {
  name: "Reset-Password",
  components: {},
  data() {
    return {
      email: "",
      pwd: "",
      confirmPwd: "",
      otpCode: "",
      otpSent: false,
      otpVerified: false,
      isSendingOtpLoading: false,
      isValidatingOtpLoading: false,
      isPasswordLoading: false,
    };
  },
  async mounted() {
    const user = this.$store.state.currentUser;

    if (user) {
      return this.$router.replace("home");
    }
  },
  computed: {
    formValid() {
      return !this.$v.email.$invalid;
    },
    otpFormValid() {
      return !this.$v.otpCode.$invalid && !this.$v.otpCode.$invalid;
    },

    year() {
      return new Date().getUTCFullYear();
    },
  },
  methods: {
    // Update Otp
    async updatePassword() {
      this.isPasswordLoading = true;
      try {
        const body = {
          email: this.email,
          pwd: this.pwd,
        };
        this.updateProgress = true;

        const url = HOST + "/password/reset";

        const req = await Http({ method: "POST", url, body });

        if (!req.status) {
          return this.showMsg(
            req.message || "an error occurred resetting password"
          );
        }
        this.showMsg("Password Reset Complete");
        setTimeout(()=>{
          this.$router.push("login");
        },1500)
      } catch (error) {
        console.log(error);
      } finally {
        this.isPasswordLoading = false;
      }
    },

    // Verify Otp
    async verifyOtp() {
      const onComeplete = () => {
        this.isValidatingOtpLoading = false;
        this.otpVerified = true;
      };

      const onFail = (err) => {
        console.log(err);
        this.isValidatingOtpLoading = false;
        this.otpVerified = false;
      };

      try {
        if (!this.otpCode || this.otpCode.lenght < 6 || this.otpCode.lenght > 6)
          return;

        const url = `${HOST}/otp/validate`;

        const payload = {
          code: this.otpCode,
          email: this.email,
        };

        this.isValidatingOtpLoading = true;

        const req = await Http({ method: "POST", url, body: payload });

        console.log(req);

        if (!req.status) {
          return this.showMsg(req.message);
        }
        onComeplete();
      } catch (error) {
        onFail(error);
      }
    },

    // Send OTP
    async sendOtp() {
      if (!this.email) return;

      const body = { email: this.email };

      const url = `${HOST}/otp/send`;

      this.isSendingOtpLoading = true;

      const req = await Http({ method: "POST", url, body });

      if (req.status) {
        this.otpSent = true;
      }
      this.isSendingOtpLoading = false;

      this.showMsg(req.message);
    },

    showMsg(msg) {
      return this.$store.commit("showMsg", msg);
    },
    closeMsg() {
      this.$store.commit("showMsg", "");
    },
  },

  validations: {
    email: {
      required,
      email,
      minLenght: minLength(3),
    },
    pwd: {
      required,
      minLength: minLength(6),
    },
    otpCode: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },
  },
};
</script>
