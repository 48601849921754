<template>
  <!-- Wrapper Starts -->
  <div class="wrapper">
    <div class="container-fluid user-auth">
      <div class="hidden-xs col-sm-2 col-md-2 col-lg-2"></div>
      <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
        <!-- Logo Ends -->
        <div class="form-container">
          <div>
            <!-- Section Title Starts -->
            <div class="row text-center">
              <h2 class="title-head hidden-xs">get <span>started</span></h2>
              <p class="info-form">
                Open account for free and start earning passively
              </p>
            </div>
            <!-- Section Title Ends -->
            <!-- Form Starts -->
            <form @submit.prevent="register()">
              <!-- Input Field Starts -->
              <div class="form-group">
                <input
                  id="firstName"
                  v-model="$v.firstName.$model"
                  class="form-control"
                  name="firstName"
                  placeholder="FRIST NAME"
                  type="text"
                  required
                />
                <p
                  v-if="!$v.firstName.required && $v.firstName.$dirty"
                  class="text-danger"
                >
                  first name is required
                </p>
              </div>
              <!-- Input Field Ends -->
              <!-- Input Field Starts -->
              <div class="form-group">
                <input
                  id="lastName"
                  v-model="$v.lastName.$model"
                  class="form-control"
                  name="lastName"
                  placeholder="LAST NAME"
                  type="text"
                  required
                />
                <p
                  v-if="!$v.lastName.required && $v.lastName.$dirty"
                  class="text-danger"
                >
                  last name is required
                </p>
              </div>
              <!-- Input Field Ends -->
              <!-- Input Field Starts -->
              <div class="form-group">
                <input
                  id="email"
                  v-model="$v.email.$model"
                  class="form-control"
                  name="email"
                  placeholder="EMAIL"
                  type="email"
                  required
                />
                <p
                  v-if="
                    (!$v.email.required || !$v.email.email) && $v.email.$dirty
                  "
                  class="text-danger"
                >
                  valid email is required
                </p>
              </div>
              <!-- Input Field Ends -->
              <!-- Input Field Starts -->
              <div class="form-group">
                <input
                  id="password"
                  v-model="$v.pwd.$model"
                  class="form-control"
                  name="password"
                  placeholder="PASSWORD"
                  type="password"
                  required
                />
                <p
                  v-if="
                    (!$v.pwd.required || !$v.pwd.minLength) && $v.pwd.$dirty
                  "
                  class="text-danger"
                >
                  valid password with min of 6 charaters is required
                </p>
              </div>

              <!-- Input Field Ends -->
              <!-- Input Field Starts -->
              <div class="form-group">
                <input
                  id="confirmPwd"
                  v-model="$v.confirmPwd.$model"
                  class="form-control"
                  name="confirmPwd"
                  placeholder="CONFIRM PASSWORD"
                  type="password"
                  required
                />
                <p
                  v-if="
                    (!$v.confirmPwd.required || !passwordMatch) &&
                      $v.confirmPwd.$dirty
                  "
                  class="text-danger"
                >
                  passwords don't match
                </p>
              </div>


              <!-- Input Field Ends -->
              <!-- Submit Form Button Starts -->
              <div class="form-group">
                <!-- Register Button -->
                <button
                  v-if="!isLoading"
                  :disabled="!formValid"
                  class="btn btn-primary"
                  type="submit"
                >
                  create account
                </button>

                <!-- Progress Bar -->
                <div class="text-center">
                  <md-progress-spinner
                    v-if="isLoading"
                    :md-diameter="20"
                    :md-stroke="3"
                    md-mode="indeterminate"
                  />
                </div>

                <!-- Already Have an Account -->
                <p class="text-center">
                  already have an account ?
                  <router-link to="login"> Login </router-link>
                </p>
              </div>

              <!-- Submit Form Button Ends -->
            </form>
            <!-- Form Ends -->
          </div>
        </div>
        <!-- Copyright Text Starts -->
        <p class="text-center copyright-text">
          <router-link to="/">TopstepTrade</router-link> © {{ year }} All Rights
          Reserved
          <router-link class="primary" to="/terms">Terms of Use</router-link>
          and <router-link to="/privacy">Privacy Policy</router-link>
        </p>
        <!-- Copyright Text Ends -->
      </div>
      <div class="hidden-xs col-sm-2 col-md-2 col-lg-2"></div>
    </div>
  </div>
  <!-- Wrapper Ends -->
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";


export default {
  name: "Register",
  components: {},
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      pwd: "",
      confirmPwd: "",
      isLoading: false,
      selectedTrader: "",
    };
  },

  validations: {
    firstName: {
      required,
      minLenght: minLength(2),
    },

    lastName: {
      required,
      minLenght: minLength(2),
    },
    email: {
      required,
      email,
      minLenght: minLength(4),
    },
    pwd: {
      required,
      minLength: minLength(6),
    },
    confirmPwd: {
      required,
    },
  },
  computed: {
    year() {
      return new Date().getUTCFullYear();
    },
    passwordMatch() {
      return this.pwd === this.confirmPwd;
    },
    formValid() {
      return (
        !this.$v.firstName.$invalid &&
        !this.$v.lastName.$invalid &&
        !this.$v.email.$invalid &&
        !this.$v.pwd.$invalid &&

        this.passwordMatch
      );
    },
  },
  methods: {
    async register() {
      this.isLoading = true;
      const credencial = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        pwd: this.pwd,
        trader: "-",
      };
      const res = await this.$store.dispatch("register", credencial);
      this.isLoading = false;


      if (!res.status) {
        return this.showMsg(
          res.message || "error occured, check your internet connection"
        );
      }

      this.showMsg("Success, Welcome to TopstepTrade" + res.data.firstName);

      this.$router.push("login");
    },

    showMsg(msg) {
      return this.$store.commit("showMsg", msg);
    },
    closeMsg() {
      this.$store.commit("showMsg", "");
    },
  },
};
</script>
