<template>
  <div class="container-fluid">
    <!-- Account Summary -->
    <div class="row">
      <div
        class="col-lg-4 col-md-6 col-sm-12"
        v-for="card in cards"
        v-bind:key="card.desc"
      >
        <md-card
          v-bind:class="[card.color, style]"
          style="transition: all 500ms ease;"
          md-with-hover
        >
          <md-card-header>
            <md-card-header-text>
              <div class="md-title">{{ card.data() | money }}</div>
              <div class="md-subhead">{{ card.desc }}</div>
            </md-card-header-text>

            <md-card-media>
              <md-icon class="md-size-3x">{{ card.icon }}</md-icon>
            </md-card-media>
          </md-card-header>
        </md-card>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div class="row">
      <div class="col-sm-12">
        <iframe
          scrolling="no"
          allowtransparency="true"
          frameborder="0"
          src="https://s.tradingview.com/embed-widget/forex-cross-rates/?locale=en#%7B%22currencies%22%3A%5B%22EUR%22%2C%22USD%22%2C%22JPY%22%2C%22BTC%22%2C%22ETH%22%2C%22LTC%22%2C%22GBP%22%2C%22CHF%22%2C%22AUD%22%2C%22CAD%22%2C%22NZD%22%2C%22CNY%22%5D%2C%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22utm_source%22%3A%22www.portal.eastendinvestment.co.uk%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22forex-cross-rates%22%7D"
          style="box-sizing: border-box; height: 500px !important; width: 100%;"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<style scoped>
.green {
  background: #43a047 !important;
}
.blue {
  background: #1e88e5 !important;
}
.btnHover:hover {
 
  -webkit-transform: scale(1.05) !important;
  -ms-transform: scale(1.05) !important;
  transform: scale(1.05) !important;
}
</style>

<script>
// import Http from "../../helpers/http";
import UTILS from "../../helpers/utils";

export default {
  name: "Dashboard",
  filters: {
    money: function(value) {
      try {
        if (Number(value) - 2) {
          return `${UTILS.moneyFormat(Number(value), "") ?? ""}`.trim();
        }
        return UTILS.moneyFormat(0);
      } catch (error) {
        console.log(error);
        return UTILS.moneyFormat(0);
      }
    },
  },
  data() {
    return {
      style: "animate__animated animate__slow animate__bounce md-elevation-10 btnHover",
      cards: [
        {
          title: "0.00",
          desc: "Capital Balance",
          icon: "account_balance",
          color: "blue",
          data: () => {
            return this.$store.state.investment.reduce(
              (p, c) => Number(c.principal) + p,
              0
            );
          },
        },
        {
          title: "0.00",
          desc: "Total Profit",
          icon: "attach_money",
          color: "green",
          data: () => {
            return this.$store.state.investment.reduce(
              (p, c) => Number(c.profit) + p,
              0
            );
          },
        },
        {
          title: "0.00",
          desc: "Total Deposit",
          icon: "monetization_on",
          color: "",
          data: () => {
            return this.$store.state.payment
              .filter((e) => e.status === "approved")
              .reduce((p, c) => Number(c.amount) + p, 0);
          },
        },
        {
          title: "0.00",
          desc: "Total Withdraw",
          icon: "payments",
          color: "md-accent",
          data: () => {
            return (
              this.$store.state.withdraw
                //   .filter((e) => e.status === "approved")
                .reduce((p, c) => Number(c.amount) + p, 0)
            );
          },
        },
        {
          title: "0.00",
          desc: "Total Earnings",
          icon: "attach_money",
          color: "blue",
          data: () => {
            return this.$store.state.investment.reduce(
              (p, c) => Number(c.earned) + p,
              0
            );
          },
        },
      ],
    };
  },
 
};
</script>
