import './style.scss';
import 'vue-material/dist/vue-material.min.css';

import Vue from 'vue';
import ToggleButton from 'vue-js-toggle-button';
import VueMaterial from 'vue-material';
import { MdBadge, MdButton, MdIcon, MdProgress, MdRipple, MdSnackbar } from 'vue-material/dist/components';
import Vuelidate from 'vuelidate';

import App from './App.vue';
import router from './router';
import store from './store';





Vue.config.silent = true
Vue.config.productionTip = false

Vue.use(VueMaterial)
Vue.use(MdButton)
Vue.use(MdProgress)
Vue.use(MdSnackbar)
Vue.use(MdIcon)
Vue.use(MdBadge)
Vue.use(MdRipple)

Vue.use(Vuelidate)

Vue.use(ToggleButton)


new Vue({
  router,
  store, 
  render: h => h(App)
}).$mount('#app')
