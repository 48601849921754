<template>
  <div>
    <md-empty-state
      v-if="withdraw.length === 0"
      md-icon="library_books"
      md-label="No Withdraws"
      md-description="You currenly have no withdraw history"
    >
      <!-- <md-button class="md-primary md-raised"></md-button> -->
    </md-empty-state>

    <md-table
      v-if="withdraw.length !== 0"
      v-model="withdraw"
      md-sort="name"
      md-sort-order="asc"
      md-card
    >
      <md-table-toolbar>
        <h1 class="md-title">Withdraw History</h1>
      </md-table-toolbar>

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Investment" md-sort-by="investmentRef">
          {{ item.investmentRef }}</md-table-cell
        >
        <md-table-cell md-label="Currency" md-sort-by="name"
          >USD
        </md-table-cell>
        <md-table-cell md-label="Amount" md-sort-by="amount"
          >{{ item.amount | money }}
        </md-table-cell>
        <md-table-cell md-label="Status" md-sort-by="status">{{
          item.status
        }}</md-table-cell>
        <md-table-cell md-label="Date" md-sort-by="timestamp">{{
          item.timestamp | date
        }}</md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import UTILS from "../../helpers/utils";
import { PLAN } from "../../store/global";

export default {
  name: "Withdraw",
  filters: {
    money: function(value) {
      try {
        if (Number(value) - 2) {
          return `${UTILS.moneyFormat(Number(value), " ") ?? ""}`.trim();
        }
        return UTILS.moneyFormat(0);
      } catch (error) {
        console.log(error);
        return UTILS.moneyFormat(0);
      }
    },
    date: function(val) {
      return new Date(val).toLocaleDateString();
    },
    plan: function(value) {
      const plan = PLAN.find(
        (e) => e.data.filter((p) => p.id === value).length > 0
      );

      return plan.data.find((e) => e.id === value).name;
    },
  },
  data() {
    return {
      withdraw: [],
    };
  },
  created() {
    this.unsubsribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "updateWithdraw") {
        this.withdraw = state.withdraw;
      }
    });
  },
  beforeDestroy() {
    this.unsubsribe();
  },
  async mounted() {
    this.$store.dispatch("getWithdraw");
  },
};
</script>
