<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 col-sm-12" style="margin-bottom:20px">
        <md-card>
          <md-card-header>
            <div class="md-title">Investment ROI Arregation</div>
          </md-card-header>

          <md-card-content>
            This will run a profit aggregtion algo on all "ACTIVE" investment
            only!
          </md-card-content>

          <md-card-actions>
            <!-- <md-progress-spinner
              v-if="aggregationProgress"
              :md-diameter="20"
              :md-stroke="3"
              md-mode="indeterminate"
            />
            <md-button
              @click="aggregate()"
              v-if="!aggregationProgress"
              class="md-raised md-primary"
              style="color:black"
              >Aggregate</md-button
            > -->
          </md-card-actions>
        </md-card>
      </div>
      <div class="col-md-4 col-sm-12">
        <md-card>
          <md-card-header>
            <div class="md-title">Update Wallet Address</div>
          </md-card-header>

          <md-card-content>
            <br />

            <span>Change wallet address client deposits to.</span>
            <br />

            <input v-model="wallet" class="form-control" placeholder="Enter Wallet Address" type="text" />
            <br />
            <br />
            <br />
            <center>
              <!-- <md-button
                class="md-icon-button md-raised md-dense md-primary"

              >
                <md-icon  style="color:#ffddd">add_photo_alternate</md-icon>
              </md-button> -->

              <img :src="qrcode" width="300" height="300" />
            </center>
          </md-card-content>

          <br />
          <br />
          <br />
          <center>
            <md-progress-spinner v-if="uploadingIdProgress" :md-diameter="20" :md-stroke="3" md-mode="indeterminate" />
            <md-button v-if="!uploadingIdProgress" class="md-raised md-primary" style="color:#fff" @click="
              selectIdCard(
                'Bitcoin Wallet Address',
                'You will be required to upload a clear Qr-Code image of your recieving bitcoin wallet address'
              )
              ">Select Barcode & Update</md-button>
          </center>
          <br />
          <br />
        </md-card>
      </div>

      <!-- CONFIRMATION DIALOG -->
      <div>
        <md-dialog-confirm :md-active.sync="confirmDialog.active" :md-title="confirmDialog.title"
          :md-content="confirmDialog.content" md-confirm-text="Accept" md-cancel-text="Decline" @md-cancel="onCancel"
          @md-confirm="confirmDialog.action" />
      </div>

      <!-- Confirm Selectcd File For Upload -->
      <div>
        <md-dialog :md-active.sync="fileConfirmationDialog.active" :md-fullscreen="false"
          :md-click-outside-to-close="false">
          <md-dialog-title>{{ fileConfirmationDialog.title }}
            <p>{{ fileConfirmationDialog.subtitle }}</p>
          </md-dialog-title>

          <p style="margin-left:20px">{{ fileConfirmationDialog.desc }}</p>

          <div v-html="fileConfirmationDialog.html"></div>
          <center>
            <md-dialog-actions>
              <md-button class="md-primary" @click="fileConfirmationDialog.active = false">Cancel</md-button>
              <md-button class="md-primary" @click="fileConfirmationDialog.action">Proceed</md-button>
            </md-dialog-actions>
          </center>
        </md-dialog>
      </div>

      <!-- FIle Picker -->
      <input type="file" v-show="false" id="file" accept="image/*" />
    </div>
  </div>
</template>
<style scoped></style>

<script>
import Http from "../../helpers/http";
import { HOST } from "../../store/global";
import * as firebase from "firebase/app";

import "firebase/storage";
import UTILS from "../../helpers/utils";

try {
  firebase.initializeApp(
    {
      apiKey: "AIzaSyBL_ZAmlrhMgp2rKSZPCmFoBAt5iN30zn4",
      authDomain: "topsteptrade-1b1c5.firebaseapp.com",
      projectId: "topsteptrade-1b1c5",
      storageBucket: "topsteptrade-1b1c5.appspot.com",
      messagingSenderId: "1040227308388",
      appId: "1:1040227308388:web:f3e53e13ca17af2a76a071",
      measurementId: "G-ZQC5EQDDFC"
    }
  );

} catch (error) {
  try {
    firebase.initializeApp(
      {
        apiKey: "AIzaSyBL_ZAmlrhMgp2rKSZPCmFoBAt5iN30zn4",
        authDomain: "topsteptrade-1b1c5.firebaseapp.com",
        projectId: "topsteptrade-1b1c5",
        storageBucket: "topsteptrade-1b1c5.appspot.com",
        messagingSenderId: "1040227308388",
        appId: "1:1040227308388:web:f3e53e13ca17af2a76a071",
        measurementId: "G-ZQC5EQDDFC"
      }, "admin"
    );

  } catch (error) {
    console.log(error);
  }
}
const storage = firebase.storage().ref();

export default {
  name: "Config",

  data() {
    return {
      wallet: "",
      qrcode: "",
      user: this.$store.state.currentUser,
      uploadingIdProgress: false,
      aggregationProgress: false,
      file: null,
      fileConfirmationDialog: {
        title: "",
        subtitle: "",
        desc: "",
        html: ``,
        active: false,
        action: () => { },
      },
      confirmDialog: {
        active: false,
        title: "",
        content: "",
        result: "",
        action: () => { },
      },
    };
  },

  methods: {
    /**
     * Upload Blob to firebase
     */
    async uploadBlob() {
      try {
        console.log("STEP 1")
        const fileName = "qrcode" || UTILS.generateRef();
        const ref = storage.child(`public/verification/${fileName}`);
        console.log("STEP 2")
        const upload = ref.putString(this.file, "data_url");
        console.log("STEP 3")
        await upload.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          () => { },
          console.log,
          async () => {
            const url = await upload.snapshot.ref.getDownloadURL();
            return await this.updateConfig(url);
          }
        );
        console.log("STEP 4")

      } catch (err) {
        console.log(err);
        this.showMsg("Error occurred uploading document");
      }
    },
    /**
     * File Picker
     */
    selectIdCard(title, desc, path) {
      if (this.user.Verified) return;

      this.showConfirmDialog(title, desc, path, async () => {
        this.uploadingIdProgress = true;

        document.getElementById("file").click();

        // On file seleted
        // Show file confirmation
        // Then register event on file change to execute final file upload confimation
        document.getElementById("file").addEventListener("change", (event) => {
          // validate file is selected
          if (!event.target.files || !event.target.files[0]) return;

          const reader = new FileReader();

          // on file read complete and call seleted file confirmation
          reader.onload = (blob) => {
            this.uploadingIdProgress = false;

            this.file = blob.target.result;

            this.selectedFileConfirmation({
              title: "Selected file for upload",
              subtitle: "Do you wish to proceed with upload",
              html: `<center><img
                class="avatar" width=300 height=300
                src='${blob.target.result}'
                alt="file upload"
              /> </center>`,
              action: async () => {
                // Upload ID
                try {
                  this.uploadingIdProgress = true;

                  await this.uploadBlob(path);
                } catch (err) {
                  this.uploadingIdProgress = false;
                  console.log(err);
                }
              },
            });
          };
          // init read file
          reader.readAsDataURL(event.target.files[0]);
        });
      });
    },

    /**
     * Upload Id Card
     */
    async updateConfig(imageUrl) {
      const url = HOST + "/config/update";

      const payload = { wallet: this.wallet, qrcode: imageUrl };

      const req = await Http({ method: "POST", url, body: payload });

      if (!req.status) {
        this.uploadingIdProgress = false;
        return this.showMsg("An error occurred while updating wallet info");
      }

      this.showMsg("Wallet Info Updated");
      this.uploadingIdProgress = false;
      location.reload();
    },

    /**
     * Show Confirmation Dialog Handle
     */
    async showConfirmDialog(title, desc, arg, action) {
      this.confirmDialog.title = title;
      this.confirmDialog.content = desc;
      this.confirmDialog.action = async () => {
        await action(arg);
        this.confirmDialog.active = false;
      };
      this.confirmDialog.active = true;
    },

    /**
     * Show Dialog
     */
    async selectedFileConfirmation({
      title = "",
      subtitle = "",
      desc = "",
      html = "",
      action = () => { },
    }) {
      this.fileConfirmationDialog.title = title;
      this.fileConfirmationDialog.subtitle = subtitle;
      this.fileConfirmationDialog.desc = desc;
      this.fileConfirmationDialog.html = html;
      this.fileConfirmationDialog.action = async () => {
        this.fileConfirmationDialog.active = false;
        await action();
      };
      this.fileConfirmationDialog.active = true;
    },

    async getConfig() {
      const config = await Http({
        url: `${HOST}/config/`,
        method: "GET",
        body: null,
      });
      if (!config.status) {
        return {};
      }

      this.wallet = config.data.wallet;
      this.qrcode = config.data.qrcode;

      return config.data;
    },

    /** ON User Status Change */
    async aggregate() {
      const url = HOST + "/investment/aggregation";

      this.aggregationProgress = true;

      const req = await Http({ method: "POST", url, body: {} });

      this.aggregationProgress = false;

      if (!req.status) {
        return alert("Agregation Failed");
      }

      alert("Agregation in progress");

      location.reload();
    },
    /**
     * Show Snack Bar
     */
    showMsg(msg) {
      return this.$store.commit("showMsg", msg);
    },

    /**
     * Close snakbar
     */
    closeMsg() {
      this.$store.commit("showMsg", "");
    },
  },

  mounted() {
    this.getConfig();
  },
};
</script>
